import React from 'react';
import { BounceLoader } from 'react-spinners';

const Loader = () => {
    return (
        <div className="loader-wrapper">
            <BounceLoader color="#15BFA3" size={120} />
            <p>Analyzing...</p>
        </div>
    );
};

export default Loader;
