import React, { useState, useEffect } from 'react';
import { getChatCompletion } from '../openaiService';
import * as Babel from '@babel/standalone';
import Loader from './Loader';

const CandidateComparison = ({ systemPrompt }) => {
    const [Component, setComponent] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAndSetComponent = async () => {
            setLoading(true);
            const response = await getChatCompletion(systemPrompt);
            if (response) {
                var rawCode = response.message.content;
                const startIndex = rawCode.indexOf('const');
                const endIndex = rawCode.indexOf('export');
                rawCode = rawCode.slice(startIndex, endIndex);
                console.log("rawCode: " + rawCode);

                try {
                    // Convert JSX to JavaScript
                    var transformedCode = Babel.transform(rawCode, { presets: ['react'] }).code;
                    const startIndex = transformedCode.indexOf('return');
                    transformedCode = transformedCode.slice(startIndex, -1);
                    console.log("transformedCode: " + transformedCode);

                    const componentCode = `
                        const CandidateComparison = () => {
                        ${transformedCode}
                        return CandidateComparison;
                    `;
                    console.log("componentCode: " + componentCode);

                    /* eslint-disable no-new-func */
                    const GeneratedComponent = new Function('React', componentCode)(React);
                    setComponent(() => GeneratedComponent);
                    /* eslint-enable no-new-func */
                } catch (error) {
                    console.error('Error evaluating component code:', error);
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchAndSetComponent();
    }, [systemPrompt]);

    return (
        <div className="analysis-wrapper">
            {loading ? <Loader /> : Component && <Component />}
        </div>
    );
};

export default CandidateComparison;
