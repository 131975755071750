import React from 'react';

const data = [
  { candidate: 'Marcela Rodriguez', location: 'New York', startedOn: 'Jun 25, 2024', knockout: '100%', fit: 'Medium', time: '40 min', words: '328 words' },
  { candidate: 'Samuel Bennett', location: 'Philadelphia', startedOn: 'Jun 25, 2024', knockout: '100%', fit: 'High', time: '31 min', words: '287 words' },
  { candidate: 'Anna Thompson', location: 'New York', startedOn: 'Jun 24, 2024', knockout: '100%', fit: 'High', time: '29 min', words: '209 words' },
  { candidate: 'Nikhil Ananth', location: 'Boston', startedOn: 'Jun 24, 2024', knockout: '100%', fit: 'High', time: '35 min', words: '215 words' },
  { candidate: 'David Lee', location: 'New York', startedOn: 'Jun 23, 2024', knockout: '100%', fit: 'Low', time: '50 min', words: '225 words' },
  { candidate: 'Emily Wong', location: 'New York', startedOn: 'Jun 22, 2024', knockout: '100%', fit: 'Medium', time: '20 min', words: '190 words' },
  { candidate: 'Michael Thompson', location: 'Boston', startedOn: 'Jun 22, 2024', knockout: '100%', fit: 'High', time: '45 min', words: '250 words' },
  { candidate: 'Sophia Martinez', location: 'New York', startedOn: 'Jun 21, 2024', knockout: '100%', fit: 'Medium', time: '37 min', words: '180 words' },
  { candidate: 'Carlos Ramirez', location: 'Philadelphia', startedOn: 'Jun 21, 2024', knockout: '100%', fit: 'Medium', time: '29 min', words: '255 words' },
  { candidate: 'Aisha Patel', location: 'Boston', startedOn: 'Jun 20, 2024', knockout: '100%', fit: 'High', time: '44 min', words: '175 words' },
  { candidate: 'Liam O\'Brien', location: 'New York', startedOn: 'Jun 20, 2024', knockout: '100%', fit: 'High', time: '39 min', words: '205 words' },
  { candidate: 'Maria Gomez', location: 'Philadelphia', startedOn: 'Jun 19, 2024', knockout: '100%', fit: 'Medium', time: '28 min', words: '260 words' },
  { candidate: 'Jamal Johnson', location: 'Boston', startedOn: 'Jun 19, 2024', knockout: '100%', fit: 'High', time: '51 min', words: '230 words' },
  { candidate: 'Yuki Nakamura', location: 'New York', startedOn: 'Jun 18, 2024', knockout: '100%', fit: 'Medium', time: '42 min', words: '245 words' },
  { candidate: 'Ahmed Hassan', location: 'New York', startedOn: 'Jun 18, 2024', knockout: '100%', fit: 'High', time: '47 min', words: '230 words' },
  { candidate: 'Eva Jensen', location: 'Boston', startedOn: 'Jun 18, 2024', knockout: '100%', fit: 'Medium', time: '38 min', words: '250 words' },
  { candidate: 'Hassan Ali', location: 'Boston', startedOn: 'Jun 18, 2024', knockout: '100%', fit: 'High', time: '40 min', words: '220 words' },
  { candidate: 'Elena Martinez', location: 'Philadelphia', startedOn: 'Jun 18, 2024', knockout: '100%', fit: 'Medium', time: '35 min', words: '270 words' },
  { candidate: 'Rajesh Kumar', location: 'Boston', startedOn: 'Jun 18, 2024', knockout: '100%', fit: 'High', time: '41 min', words: '195 words' },
  { candidate: 'Linda Nguyen', location: 'New York', startedOn: 'Jun 18, 2024', knockout: '100%', fit: 'High', time: '39 min', words: '210 words' },
  { candidate: 'Fatima Ahmed', location: 'Philadelphia', startedOn: 'Jun 18, 2024', knockout: '100%', fit: 'High', time: '42 min', words: '220 words' },
  { candidate: 'Javier Diaz', location: 'Boston', startedOn: 'Jun 18, 2024', knockout: '100%', fit: 'Medium', time: '47 min', words: '215 words' },
  { candidate: 'Sarah Kim', location: 'Philadelphia', startedOn: 'Jun 18, 2024', knockout: '100%', fit: 'High', time: '33 min', words: '230 words' },
  { candidate: 'John Smith', location: 'Philadelphia', startedOn: 'Jun 18, 2024', knockout: '100%', fit: 'Low', time: '34 min', words: '240 words' },
  { candidate: 'Angela Lopez', location: 'Boston', startedOn: 'Jun 18, 2024', knockout: '100%', fit: 'High', time: '52 min', words: '260 words' },
  { candidate: 'Darius Washington', location: 'New York', startedOn: 'Jun 18, 2024', knockout: '100%', fit: 'High', time: '37 min', words: '215 words' },
  { candidate: 'Chen Liu', location: 'Philadelphia', startedOn: 'Jun 18, 2024', knockout: '100%', fit: 'High', time: '43 min', words: '235 words' },
  { candidate: 'Emily White', location: 'Boston', startedOn: 'Jun 18, 2024', knockout: '100%', fit: 'High', time: '38 min', words: '220 words' },
  { candidate: 'Lena Rodriguez', location: 'Philadelphia', startedOn: 'Jun 18, 2024', knockout: '100%', fit: 'Medium', time: '40 min', words: '225 words' },
  { candidate: 'Robert Johnson', location: 'Philadelphia', startedOn: 'Jun 18, 2024', knockout: '100%', fit: 'High', time: '41 min', words: '230 words' }
];

const ScreeningTable = () => {
  return (
    <div className='screenings-wrapper'>
      <h1 className='small-caps'>Customer Service Representative screenings</h1>
      <div className='table'>
        <div className='row first-row small-caps'>
          <div className='cell'>Candidate</div>
          <div className='cell'>Location</div>
          <div className='cell'>Started on</div>
          <div className='cell'>Knockout</div>
          <div className='cell'>Overall fit</div>
          <div className='cell'>Time</div>
          <div className='cell'>Words</div>
        </div>
        {data.map((item, index) => (
          <div className='row' key={index}>
            <div className='cell'>{item.candidate}</div>
            <div className='cell'>{item.location}</div>
            <div className='cell'>{item.startedOn}</div>
            <div className='cell'><span className='badge'>{item.knockout}</span></div>
            <div className='cell'>
              <span className={`badge fixed-width ${item.fit === 'Low' ? 'low' : item.fit === 'Medium' ? 'medium' : ''}`}>{item.fit}</span>
            </div>
            <div className='cell'>{item.time}</div>
            <div className='cell'>{item.words}</div>
          </div>
        ))}

      </div>
    </div>
  );
};

export default ScreeningTable;
