import React, { useState } from "react";
import CandidateComparison from "./components/CandidateComparison";
import ScreeningTable from "./components/ScreeningTable";

const App = () => {
  const [userInput, setUserInput] = useState("");
  const [systemPrompt, setSystemPrompt] = useState("");
  const [error, setError] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const suggestedCommands = [
    "Who has the most leadership potential?",
    "Who has the strongest technical skills?",
    "What was Sara's most important quote?",
    "Did John give any red-flag answers?",
    "Compare and contrast Samuel and Aisha",
    "Who has the most relevant work experience?",
    "Give me a bulleted list Nikhil, Emily, and Sophia's strenghts.",
    "Who are the best team players?"
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userInput) {
      const hardcodedString = `
      You are an expert recruiter and React developer. You will get a query about a number of candidates for a Customer Service Representative position and a screener transcript for each candidate.
      
      Based on the query and the transcripts, create a well-structured React component that thoroughly answers the query. Only output the code for the React component. The component should include two main sections, both wrapped in a single "div" with the class "analysis-grid":

      The company hiring is called Acme Incorporated, and their values are teamwork, outstanding customer service, and integrity. The Customer Service Rep role involves answering customers' product questions, pointing people toward the right products, and negotiating refunds. The ideal candidate is detail-oriented, personable, and organized.

      1) A "div" with the class "summary". This section should summarize your final response to the query.

      2) Several "div"s, each with the class "analysis-card". Each div in this section should support your final response. For example, if the query is "which candidates have the most leadership potential", there might be an "analysis-card" for each of the top three candidates. Each "analysis-card" might include a relevant quote from the candidate's transcript and a short analysis of the quote. The content of each "analysis-card" will vary and should always be relevant to the query. Always refer to candidates by their first names, not by their numbers.
      
      Query: 
      `;
      const combinedString = String(hardcodedString) + String(userInput);
      console.log("combinedString: " + combinedString);
      setSystemPrompt(combinedString);
      console.log("systemPrompt: " + combinedString);
      setError(null);
    } else {
      setError("Please enter a prompt.");
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setUserInput(suggestion);
    setShowSuggestions(false);
    setTimeout(() => {
      const event = new Event("submit", { bubbles: true, cancelable: true });
      document.querySelector("form").dispatchEvent(event);
    }, 100); // Delay to ensure the state updates
  };

  return (
    <div>
      <form className="command-bar" onSubmit={handleSubmit}>
        <input
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          onFocus={() => setShowSuggestions(true)}
          onBlur={() => setTimeout(() => setShowSuggestions(false), 100)} // Delay to allow click
          placeholder="What do you want to know about your candidates?"
        />
        <button type="submit" id="submitButton">Analyze</button>
      </form>
      {showSuggestions && (
        <div className="suggestions">
          {suggestedCommands.map((command, index) => (
            <div key={index} onClick={() => handleSuggestionClick(command)} className="suggestion">
              <div>{command}</div>
            </div>
          ))}
        </div>
      )}
      {error && <p style={{ color: "red" }}>{error}</p>}
      {systemPrompt && <CandidateComparison systemPrompt={systemPrompt} />}
      <ScreeningTable />
    </div>
  );
};

export default App;
